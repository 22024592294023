import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export const DrawHeaderImage = ({ image }) => {
  if (image && image.localFile) {
    return <Img fluid={image.localFile.childImageSharp.fluid} />
  } else {
    return null;
  }
}

export const PageTemplate = ({ title, content, headerImage }) => {
  return (
    <section className="page-wrapper">
      <DrawHeaderImage image={headerImage} />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO
        title={page.title}
        description={(page.acf.seo ? page.acf.seo.meta_description : null)}
      />
      <PageTemplate title={page.title} content={page.content} headerImage={page.acf.image} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        seo {
          meta_description
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1230) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
